/*! CSS Used from: https://www.facelessnft.net/static/css/2.0acf608e.chunk.css */
.wallet-adapter-button{background-color:#404144;border:none;border-radius:6px;color:#fff;cursor:pointer;display:flex;align-items:center;font-family:"Inter","Roboto","Helvetica Neue",Helvetica,Arial,sans-serif;font-size:16px;font-weight:600;height:50px;line-height:50px;padding:0 20px;}
.wallet-adapter-button:not([disabled]):hover{background-image:linear-gradient(rgba(0,0,0,.15) 0 0);}
/*! CSS Used from: https://www.facelessnft.net/static/css/main.193891a1.chunk.css */
body{margin:0;font-family:"Montserrat",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
*{box-sizing:border-box;}
:root{--transparent-dark-color:rgba(0,0,0,0.4);--dark-shadox:5px 5px 22px 0px rgba(0,0,0,0.19);--shine-tilt:60%;--holographicBkg-width:400px;--holographicBkg-height:auto;--light-holo-color:rgba(207,223,220,0.23921568627450981);--dark-holor-color:#8ed5c9;--shine-color:255,255,255;--repeating-gradient:repeating-linear-gradient(-45deg,transparent 30%,rgba(var(--shine-color),1) 30% 50%);--shadow:rgba(0,0,0,0.2);--glow:-2px 0 30px 20px;--back-distance:-1rem;--nav-font-size:1em;--nav-padding:calc(var(--nav-font-size)*1.5);--side-bar-pad:4rem 2rem;--largeBreakpoint:1000px;--yellow-color:#f0b90b;--main-bkg-color:#000;--main-bkg-color-lighter:#3d3d3d;--main-bkg-color-darker:#111;--bkg-gradient:radial-gradient(circle,#272727 0%,#000 100%);--patternBg:url(https://www.transparenttextures.com/patterns/worn-dots.png);--minimum-height:650px;--nav-big-btn-color:#f14646;--nav-big-button-color-hover:#f85f5f;--event-color:#fff;--accent:#004ba8;--disabled:#282828;--almost-white:#d6d6d6;}
.second-schema{--yellow-color:#03e1ff;--nav-big-btn-color:#dc1fff;--nav-big-button-color-hover:#e25bfa;--main-bkg-color:#1c1c1c;--event-color:#000;--light-holo-color:rgba(124,240,255,0.16470588235294117);--dark-holor-color:rgba(166,240,250,0.7803921568627451);--accent:#d6d6d6;--disabled:#666;}
html{background-image:url(https://www.transparenttextures.com/patterns/worn-dots.png);background-image:var(--patternBg);background-image:url(https://www.transparenttextures.com/patterns/worn-dots.png),radial-gradient(circle,#272727 0,#000 100%);background-image:var(--patternBg),var(--bkg-gradient);background-size:10%;scroll-behavior:smooth;}
#root,body,html{max-width:100vw;overflow-x:hidden;}
html{min-width:100vw;min-height:100vh;}
*{font-family:"Monsterrat",sans-serif!important;}
.nav_nav__3AJrQ{z-index:1000;font-style:italic;width:100%;margin:auto;background-color:transparent;display:flex;grid-gap:2%;gap:2%;box-sizing:border-box;list-style:none;position:fixed;font-size:var(--nav-font-size);top:0;left:0;white-space:nowrap;padding:10px 15%;transition:all .5s ease;}
.nav_connectWallet__1BgMt{background-color:transparent;color:#fff;transition:all .3s ease;font-style:italic;padding:3px 10px;height:30px;font-family:inherit;margin-right:0;cursor:pointer;border:1px solid var(--main-bkg-color-lighter);text-transform:lowercase;border-radius:10px;}
.nav_connectWallet__1BgMt:hover{background-color:var(--accent);}
.nav_nav__3AJrQ .nav_wrapImage__28E2D{cursor:pointer;flex-shrink:0;position:relative;display:flex;align-items:center;justify-content:center;font-size:20pt;width:100px;}
.nav_nav__3AJrQ .nav_wrapImage__28E2D img{height:80%;width:auto;transition:all .3s ease;position:absolute;left:20px;}
.nav_nav__3AJrQ .nav_wrapImage__28E2D img:hover{-webkit-transform:scale(1.05);transform:scale(1.05);}
.nav_nav__3AJrQ a{text-decoration:none;color:#fff;display:flex;justify-content:center;cursor:pointer;transition:all .2s ease;}
.nav_nav__3AJrQ a.nav_selectedLink__22Vrq{color:var(--accent)!important;}
.nav_nav__3AJrQ a:hover:not(.nav_navBigBtn__1gd90){color:var(--accent);}
.nav_wrapText__dAZUQ{box-sizing:border-box;display:flex;grid-gap:5%;gap:5%;justify-content:flex-end;align-items:center;padding:var(--nav-padding);flex-grow:1;position:relative;transition:all .4s ease;}
.nav_sideBar__g565c{max-width:100vw;z-index:-1;width:30rem;padding:var(--side-bar-pad);background:var(--main-bkg-color);color:#fff;height:100vh;position:fixed;right:0;top:0;box-sizing:border-box;font-size:calc(1.3rem + .7vw);display:flex;flex-direction:column;justify-content:space-between;overflow:auto;grid-gap:5%;gap:5%;transition:width .3s;min-height:var(--minimum-height);box-shadow:-10px 13px 26px -10px rgba(0,0,0,.29);}
.nav_sideBar__g565c li{display:block;margin:1em 0;}
@media (max-width:1040px){
:root{--nav-font-size:0.8rem;}
.nav_nav__3AJrQ{padding:0 5%;}
}
.socialBar_socialBar__2O78Q{z-index:0;display:flex;flex-direction:row;box-sizing:border-box;justify-content:center;align-items:center;height:100%;}
.socialBar_socialBar__2O78Q>a{text-align:center;text-decoration:none;color:#fff;margin:0 15px;height:15px;width:15px;display:flex;align-items:center;}
.socialBar_socialBar__2O78Q>a:first-child{margin-left:0;}
.socialBar_socialBar__2O78Q>a:last-child{margin-right:0;}
.socialBar_socialBar__2O78Q>a>svg{fill:#fff;transition:.3s;}
.socialBar_socialBar__2O78Q>a svg:hover{-webkit-transform:scale(1.2);transform:scale(1.2);}
@media (max-width:1040px){
:root{--nav-font-size:0.8rem;}
}
.landingPage_landingPage__2iwzv{width:100vw;box-sizing:border-box;display:flex;flex-direction:column;-webkit-animation:landingPage_fadeIn__1JZeD 1.5s ease;animation:landingPage_fadeIn__1JZeD 1.5s ease;overflow:hidden;margin-top:100px;}
.landingPage_landingPage__2iwzv>div:last-child{margin-bottom:0!important;}
.landingPage_heroSection__11-5s{max-width:75%;flex-grow:1;display:flex;align-items:center;justify-content:space-between;padding-bottom:100px;height:calc(100vh - 90px);margin:1em auto auto;transition:-webkit-transform .25s ease;transition:transform .25s ease;transition:transform .25s ease,-webkit-transform .25s ease;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;will-change:transform;}
.landingPage_heroSection__11-5s .landingPage_scrollDown__12HQx{position:absolute;bottom:50px;left:50%;width:30px;height:30px;color:#fff;-webkit-animation:landingPage_float__wI1Sk 2s ease infinite;animation:landingPage_float__wI1Sk 2s ease infinite;border-radius:100%;cursor:pointer;}
.landingPage_scrollSection__3cSFk{margin-bottom:100px!important;color:#fff;width:70%;margin:auto;position:relative;}
.landingPage_scrollSection__3cSFk:before{content:"";position:absolute;top:-25px;left:50%;width:99%;-webkit-transform:translateX(-50%);transform:translateX(-50%);height:1px;background-color:var(--main-bkg-color-lighter);}
.landingPage_actionBtn__1yPzV{background-color:var(--accent);border:none;color:#000;font-size:calc(var(--nav-font-size));width:100%;font-family:inherit;cursor:pointer;border-radius:5px;position:relative;overflow:hidden;box-sizing:border-box;padding:10px 30px;text-decoration:none;box-shadow:0 0 24px var(--accent);font-size:13pt;text-transform:capitalize;}
.landingPage_discordBtnContainer__1AqQQ{width:100%;display:flex;align-items:center;justify-content:center;}
.landingPage_discordBtnContainer__1AqQQ button{color:#fff;outline:none;border:none;background:none;width:100%;cursor:pointer;}
.landingPage_discordBtn__n7YzM{width:98%;display:inline-block;background:var(--main-bkg-color-lighter);color:#fff;transition:all .3s ease;font-style:italic;padding:10px 30px;font-family:inherit;margin:0;cursor:pointer;border:1px solid var(--main-bkg-color-lighter);text-transform:lowercase;border-radius:5px;}
.landingPage_discordBtn__n7YzM:hover{background-color:var(--accent);}
.landingPage_mintCount__13P9x{position:absolute;right:2em;top:50%;-webkit-transform:translateY(-45%);transform:translateY(-45%);font-size:8pt!important;}
.landingPage_mainText__3QZoc{flex:1 1;max-width:48em;display:flex;flex-direction:column;justify-content:center;color:#fff;position:relative;z-index:1;transition:-webkit-transform .3s ease;transition:transform .3s ease;transition:transform .3s ease,-webkit-transform .3s ease;}
.landingPage_mainText__3QZoc .landingPage_header__2J2rI{padding-left:3px;font-style:italic;font-weight:bolder;font-size:35pt;text-align:left;-webkit-transform:translateX(5px);transform:translateX(5px);margin-bottom:0;font-family:"Lexend",sans-serif;font-weight:700;}
.landingPage_headerSectionTop__qr59y{display:none;}
.landingPage_mainText__3QZoc .landingPage_description__2gfnR{padding-left:9px;font-family:"Monsterrat",sans-serif!important;color:var(--almost-white);line-height:2em!important;font-size:11pt;}
@media (max-width:1500px){
.landingPage_mintCount__13P9x{position:relative;right:0;margin-bottom:0!important;-webkit-transform:translateY(0);transform:translateY(0);}
.landingPage_actionBtn__1yPzV{flex-direction:column;}
.landingPage_actionBtn__1yPzV,.landingPage_actionBtn__1yPzV span{display:flex;justify-content:center;align-items:center;}
}
@media (max-width:1200px){
.landingPage_scrollSection__3cSFk{width:85%;}
.landingPage_heroSection__11-5s{max-width:80%;height:auto;}
}
@media (max-width:1040px){
.landingPage_headerSection__3sE9_{display:none;}
.landingPage_header__2J2rI{text-align:center!important;padding-left:0;}
.landingPage_headerSectionTop__qr59y{display:flex;flex-direction:column;align-items:center;justify-content:center;margin-bottom:1em;}
.landingPage_headerSectionTop__qr59y .landingPage_header__2J2rI{font-style:italic;font-size:35pt;margin-bottom:0;font-family:"Lexend",sans-serif;font-weight:700;color:var(--almost-white);}
}
@media (max-width:768px){
.landingPage_heroSection__11-5s{flex-direction:column;}
.landingPage_headerSection__3sE9_{display:block;}
.landingPage_headerSectionTop__qr59y{display:none;}
}
@media (max-width:480px){
.landingPage_mainText__3QZoc .landingPage_header__2J2rI{font-size:2rem;}
}
.mainPageInfo_mainPageInfo__1PR5r{width:100%;max-width:26em;display:flex;flex-direction:column;align-items:center;-webkit-filter:drop-shadow(8px 8px 10px var(--main-bkg-color-darker))!important;filter:drop-shadow(8px 8px 10px var(--main-bkg-color-darker))!important;border-radius:10px;margin-right:2em;}
.mainPageInfo_containMain__2l-So{width:100%;display:flex;align-items:center;justify-content:center;border-radius:10px;overflow:hidden;}
.mainPageInfo_gif__2mllu{width:100%;height:auto;}
@media (max-width:1040px){
.mainPageInfo_mainPageInfo__1PR5r{max-width:40%;}
}
@media (max-width:768px){
.mainPageInfo_mainPageInfo__1PR5r{margin-right:0;max-width:60%;}
.mainPageInfo_containMain__2l-So{margin-right:0;}
}
@media (max-width:480px){
.mainPageInfo_mainPageInfo__1PR5r{max-width:80%;}
}
.faq_container__FSv9-{width:100%;margin:auto;font-weight:300;}
.faq_accordion__21irD{height:60px;overflow-y:hidden;position:relative;margin-bottom:30px;background:rgba(0,0,0,.3);border:1px solid var(--main-bkg-color-lighter);padding:1em;border-radius:10px;}
.faq_dropdownButton__3yU3Q{position:absolute;right:10px;top:13px;transition:all .3s ease;}
.faq_question__1vwwG{color:var(--accent);display:block;font-style:italic;font-size:13pt;width:90%;cursor:pointer;height:100%;position:absolute;top:50%;left:20px;-webkit-transform:translateY(-50%);transform:translateY(-50%);display:flex;align-items:center;overflow-x:hidden;}
.faq_answer__1AJx-{width:90%;margin-left:30px;margin-top:20px;opacity:0;}
@media (max-width:1200px){
.faq_container__FSv9-{width:100%;}
}
h1{margin-bottom:50px;font-style:italic;text-align:center;font-family:"Lexend",sans-serif;font-weight:600;}
.roadmap_roadmapContainer__2TDUg{display:flex;width:100%;margin:auto;flex-direction:column;justify-content:space-between;font-family:"Lexend",sans-serif;font-weight:300;}
.roadmap_roadmapLeft__15X7o{position:relative;flex:2 1;display:flex;padding-top:20px;justify-content:center;flex-direction:column;align-items:center;}
.roadmap_roadmapLeft__15X7o .roadmap_roadmapImage__21PqX{width:90%;height:auto;margin:1em 0;-webkit-filter:drop-shadow(8px 8px 10px var(--main-bkg-color));filter:drop-shadow(8px 8px 10px var(--main-bkg-color));}
.roadmap_roadmapRight__3jVI4{flex:1 1;display:flex;align-items:center;padding-top:35px;justify-content:center;}
.roadmap_roadmapRight__3jVI4 .roadmap_sampleImage__2-0FL{max-width:200px;margin:5px;border-radius:10px;display:flex;-webkit-filter:drop-shadow(8px 8px 10px var(--main-bkg-color));filter:drop-shadow(8px 8px 10px var(--main-bkg-color));transition:-webkit-transform .1s ease;transition:transform .1s ease;transition:transform .1s ease,-webkit-transform .1s ease;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;position:relative;will-change:transform;}
.roadmap_header__nYz7H{margin-bottom:.5em;}
@media (max-width:1200px){
.roadmap_roadmapContainer__2TDUg{flex-direction:column;}
.roadmap_roadmapImage__21PqX{width:90%!important;height:10%!important;-webkit-filter:drop-shadow(8px 8px 10px var(--main-bkg-color));filter:drop-shadow(8px 8px 10px var(--main-bkg-color));}
}
@media (max-width:900px){
.roadmap_roadmapRight__3jVI4{justify-content:flex-start;overflow-x:auto;scroll-behavior:smooth;-webkit-overflow-scrolling:touch;}
}
.community_socials__3rlqw{position:relative;display:flex;justify-content:center;flex-direction:column;height:200px;}
.community_socials__3rlqw .community_footer__1hEmj{-webkit-transform:translateX(6px);transform:translateX(6px);margin:auto auto 15px;}
.community_socials__3rlqw h1{margin-bottom:10px;}
.traits_traitsContainer__2s5p9{display:flex;flex-direction:column;justify-content:center;align-items:center;width:95%;text-align:center;border-radius:1em;background:var(--main-bkg-color-lighter);color:var(--almost-white);margin:50px auto auto;background:rgba(0,0,0,.3);border:1px solid var(--main-bkg-color-lighter);padding:1em;border-radius:10px;}
.traits_comingSoon__PLHqi{-webkit-filter:drop-shadow(0 0 10px var(--accent));filter:drop-shadow(0 0 10px var(--accent));}
.traits_traitsContainer__2s5p9 .traits_header__GpAHg{font-weight:700;margin:0;}
.traits_body__1p_vq{width:85%;max-width:800px;}
.team_teamContainer__1GRX-{width:100%;display:flex;flex-direction:column;}
.team_teams__shs55{width:100%;display:flex;justify-content:center;flex-wrap:wrap;}
.team_team__urYCS{display:flex;flex-direction:column;align-items:center;justify-content:center;margin:2em;}
.team_team__urYCS:first-child{width:100%;}
.team_avatar__18nva{max-width:250px;border-radius:10px;-webkit-filter:drop-shadow(8px 8px 10px var(--main-bkg-color));filter:drop-shadow(8px 8px 10px var(--main-bkg-color));transition:-webkit-transform .1s ease;transition:transform .1s ease;transition:transform .1s ease,-webkit-transform .1s ease;-webkit-transform-style:preserve-3d;transform-style:preserve-3d;position:relative;will-change:transform;}
.team_url__3PkDm{border:1px solid var(--main-bkg-color-lighter);padding:.25em 3em;border-radius:10px;margin-top:1em;display:flex;align-items:center;justify-content:center;cursor:pointer;transition:all .25s;}
.team_url__3PkDm:hover{-webkit-transform:scale(.97);transform:scale(.97);}
.team_url__3PkDm>svg{fill:#fff;transition:.3s;width:1em;height:1em;}
.team_details__2QGQ8{display:flex;flex-direction:column;align-items:center;justify-content:center;}
.team_bio__INaw0{max-width:400px;text-align:center;font-size:12pt;color:#d3d3d3;}
.team_name__ebC3K{width:auto;margin-bottom:0;max-width:300px;text-align:center;}
@media (max-width:900px){
.team_teams__shs55{grid-template-columns:auto;}
.team_name__ebC3K,.team_role__1aaXe{text-align:left;}
.team_team__urYCS:not(:first-child){flex-direction:row;justify-content:flex-start;}
.team_avatar__18nva{max-width:50%;margin-bottom:0;margin-right:2em;}
.team_details__2QGQ8{align-items:flex-start;}
.team_first__3_ZFl .team_details__2QGQ8{align-items:center!important;}
.team_first__3_ZFl img{margin-right:0;}
}
@media (max-width:480px){
.team_name__ebC3K{font-size:1rem;}
}
.comic_comic__2d2ew{-webkit-filter:drop-shadow(8px 8px 10px var(--main-bkg-color));filter:drop-shadow(8px 8px 10px var(--main-bkg-color));}
.comic_moveRight__1j9hZ{top:50%;-webkit-transform:translateY(-50%);transform:translateY(-50%);}
.comic_moveRight__1j9hZ{right:0;}
.comic_pageButton__ImbhB{position:absolute;cursor:pointer;background:var(--main-bkg-color-lighter);border-radius:100%;display:flex;justify-content:center;align-items:center;z-index:10;-webkit-filter:drop-shadow(8px 8px 10px var(--main-bkg-color));filter:drop-shadow(8px 8px 10px var(--main-bkg-color));width:50px;height:50px;transition:all .3s ease;}
.comic_pageButton__ImbhB:active{background:var(--main-bkg-color);}
.comic_pageButton__ImbhB:hover{-webkit-transform:translateY(-40%);transform:translateY(-40%);}
.comic_pdfWrapper__nqk1w{position:relative;display:flex;justify-content:center;align-items:center;}
.comic_pdfWrapper__nqk1w div{height:800px!important;width:auto!important;}
.comic_pdfWrapper__nqk1w div canvas{height:100%;}
@media (max-width:1500px){
.comic_pdfWrapper__nqk1w{width:95%;margin:auto;}
.comic_pdfWrapper__nqk1w div{width:100%!important;height:auto!important;}
.comic_pdfWrapper__nqk1w div canvas{width:100%!important;}
.comic_moveRight__1j9hZ{top:65%;}
}
/*! CSS Used from: Embedded */
._1CDf-Fj0nLQTDw84Sho5JS{margin:0 auto;}
/*! CSS Used from: Embedded */
html{margin:0;padding:0;}
body{margin:0;padding:0;}
/*! CSS Used from: Embedded */
.MuiSvgIcon-root{fill:currentColor;width:1em;height:1em;display:inline-block;font-size:1.5rem;transition:fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;flex-shrink:0;user-select:none;}
/*! CSS Used from: Embedded */
.MuiTouchRipple-root{top:0;left:0;right:0;bottom:0;z-index:0;overflow:hidden;position:absolute;border-radius:inherit;pointer-events:none;}
/*! CSS Used from: Embedded */
.MuiButtonBase-root{color:inherit;border:0;cursor:pointer;margin:0;display:inline-flex;outline:0;padding:0;position:relative;align-items:center;user-select:none;border-radius:0;vertical-align:middle;-moz-appearance:none;justify-content:center;text-decoration:none;background-color:transparent;-webkit-appearance:none;-webkit-tap-highlight-color:transparent;}
.MuiButtonBase-root::-moz-focus-inner{border-style:none;}
.MuiButtonBase-root.Mui-disabled{cursor:default;pointer-events:none;}
@media print{
.MuiButtonBase-root{-webkit-print-color-adjust:exact;}
}
/*! CSS Used from: Embedded */
.MuiButton-root{color:rgba(0, 0, 0, 0.87);padding:6px 16px;font-size:0.875rem;min-width:64px;box-sizing:border-box;transition:background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;font-family:"Roboto", "Helvetica", "Arial", sans-serif;font-weight:500;line-height:1.75;border-radius:4px;letter-spacing:0.02857em;text-transform:uppercase;}
.MuiButton-root:hover{text-decoration:none;background-color:rgba(0, 0, 0, 0.04);}
.MuiButton-root.Mui-disabled{color:rgba(0, 0, 0, 0.26);}
@media (hover: none){
.MuiButton-root:hover{background-color:transparent;}
}
.MuiButton-root:hover.Mui-disabled{background-color:transparent;}
.MuiButton-label{width:100%;display:inherit;align-items:inherit;justify-content:inherit;}
.MuiButton-text{padding:6px 8px;}
/*! CSS Used from: Embedded */
.MuiTypography-root{margin:0;}
.MuiTypography-caption{font-size:0.75rem;font-family:"Roboto", "Helvetica", "Arial", sans-serif;font-weight:400;line-height:1.66;letter-spacing:0.03333em;}
.MuiTypography-overline{font-size:0.75rem;font-family:"Roboto", "Helvetica", "Arial", sans-serif;font-weight:400;line-height:2.66;letter-spacing:0.08333em;text-transform:uppercase;}
/*! CSS Used keyframes */
@-webkit-keyframes landingPage_fadeIn__1JZeD{0%{opacity:0;-webkit-transform:scale(.95);transform:scale(.95);}to{opacity:1;-webkit-transform:scale(1);transform:scale(1);}}
@keyframes landingPage_fadeIn__1JZeD{0%{opacity:0;-webkit-transform:scale(.95);transform:scale(.95);}to{opacity:1;-webkit-transform:scale(1);transform:scale(1);}}
@-webkit-keyframes landingPage_float__wI1Sk{0%{-webkit-transform:translate(-50%,10px);transform:translate(-50%,10px);}50%{-webkit-transform:translate(-50%);transform:translate(-50%);}to{-webkit-transform:translate(-50%,10px);transform:translate(-50%,10px);}}
@keyframes landingPage_float__wI1Sk{0%{-webkit-transform:translate(-50%,10px);transform:translate(-50%,10px);}50%{-webkit-transform:translate(-50%);transform:translate(-50%);}to{-webkit-transform:translate(-50%,10px);transform:translate(-50%,10px);}}
/*! CSS Used fontfaces */
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZJhjp-Ek-_EeAmM.woff) format('woff');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZthjp-Ek-_EeAmM.woff) format('woff');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZNhjp-Ek-_EeAmM.woff) format('woff');unicode-range:U+1F00-1FFF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZxhjp-Ek-_EeAmM.woff) format('woff');unicode-range:U+0370-03FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZBhjp-Ek-_EeAmM.woff) format('woff');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZFhjp-Ek-_EeAmM.woff) format('woff');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Inter';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/inter/v7/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYAZ9hjp-Ek-_EeA.woff) format('woff');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZFgrz8fZwjimrq1Q_.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZFgrz-PZwjimrq1Q_.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZFgrz8_Zwjimrq1Q_.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZFgrz8vZwjimrq1Q_.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZFgrz_PZwjimrqw.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZbgjz8fZwjimrq1Q_.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZbgjz-PZwjimrq1Q_.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZbgjz8_Zwjimrq1Q_.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZbgjz8vZwjimrq1Q_.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:italic;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUPjIg1_i6t8kCHKm459WxZbgjz_PZwjimrqw.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459WRhyyTh89ZNpQ.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459W1hyyTh89ZNpQ.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459WZhyyTh89ZNpQ.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459WdhyyTh89ZNpQ.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTUSjIg1_i6t8kCHKm459WlhyyTh89Y.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gTD_vx3rCubqg.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3g3D_vx3rCubqg.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gbD_vx3rCubqg.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gfD_vx3rCubqg.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_bZF3gnD_vx3rCs.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3gTD_vx3rCubqg.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3g3D_vx3rCubqg.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3gbD_vx3rCubqg.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3gfD_vx3rCubqg.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:800;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_c5H3gnD_vx3rCs.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3gTD_vx3rCubqg.woff2) format('woff2');unicode-range:U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3g3D_vx3rCubqg.woff2) format('woff2');unicode-range:U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3gbD_vx3rCubqg.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3gfD_vx3rCubqg.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Montserrat';font-style:normal;font-weight:900;font-display:swap;src:url(https://fonts.gstatic.com/s/montserrat/v18/JTURjIg1_i6t8kCHKm45_epG3gnD_vx3rCs.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAIIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAJIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:300;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAHIAUqAggA.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAIIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAJIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:400;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAHIAUqAggA.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAIIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAJIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:500;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAHIAUqAggA.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAIIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAJIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:600;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAHIAUqAggA.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAIIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAJIAUqAggAOAE=.woff2) format('woff2');unicode-range:U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;}
@font-face{font-family:'Lexend';font-style:normal;font-weight:700;font-display:swap;src:url(https://fonts.gstatic.com/s/lexend/v7/~CggKBkxleGVuZBAHIAUqAggA.woff2) format('woff2');unicode-range:U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;}
span{
    text-decoration: none;
    border:none;
}
.x{
    font-weight: 800;
    font-style: initial;
    text-align: center;
}
.landingPage_actionBtn__1yPzV{
    width: 98%;
    align-self: center;
    font-weight: 700;
    padding: 15px 0;
}